import React from 'react';
import { APP_TYPES } from './App';
import { convertCountryCodes, convertLanguageCodeList } from '../utils/GeneralUtils';
import isEmpty from 'lodash.isempty';

export const PROFILE_LABEL = 'Profile';
export const GNVIEW_PLAN_DETAILS_LABEL = 'Gracenote View Plan Details';
export const GNID_PLAN_DETAILS_LABEL = 'ID Distribution System Plan Details';
export const NOT_AVAILABLE = 'N/A';
export const CONTACT_SUPPORT_LINK = "https://www.gracenote.com/contact-video-support/";
export const CONTACT_SUPPORT = {
    text: "Contact Support",
    url: CONTACT_SUPPORT_LINK
};
export const CONTACT_SUPPORT_MESSAGE = <span>If you have any questions/concerns about your plan, please <a href={CONTACT_SUPPORT_LINK} target='_blank' rel='noreferrer' className='contact-link'>contact your customer service representative.</a></span>

export const PROFILE_MENU_OPTIONS = {
    ACCOUNT_SETTINGS: 'Account Settings',
    ADMIN_PANEL: 'Admin Panel',
    LOGOUT: 'Logout'
};

export const ACCOUNT_SETTINGS_TAB_OPTIONS = {
    ACCOUNT_INFO: 'Account Info',
    GRACENOTE_VIEW_SETTINGS: 'Gracenote View Settings',
    SUPPORT: 'Support'
};

export const hasMobiusEntitlement = entitlements => !isEmpty(entitlements?.mobius?.include?.sourceId);

const entitlementIsEmpty = (entitlementArray) => entitlementArray?.length === 0;

const formatAdditionalFeatures = (programsEntitlements) => {
    if (programsEntitlements.adult && programsEntitlements.videoDescriptor) {
        return ['Adult', 'Video Descriptors'];
    } else if (programsEntitlements.adult) {
        return 'Adult';
    } else if (programsEntitlements.videoDescriptor) {
        return 'Video Descriptors';
    } else {
        return NOT_AVAILABLE;
    }
};

const formatServices = (entitlements) => {
    if ((entitlements.programs || entitlements.schedules) && hasMobiusEntitlement(entitlements)) {
        return [APP_TYPES.GNVIEW, APP_TYPES.GNID];
    } else if (entitlements.programs || entitlements.schedules) {
        return APP_TYPES.GNVIEW;
    } else if (hasMobiusEntitlement(entitlements)) {
        return APP_TYPES.GNID;
    } else {
        return NOT_AVAILABLE;
    }
};

export const PROFILE_FIELDS = [
    { prop: 'accountType', label: 'Account Type'},
    { prop: 'name', label: 'Account Name' },
    { prop: 'email', label: 'Primary Email' },
    { prop: 'organization', label: 'Organization Name' },
    { prop: 'services', label: 'Services' }
];

export const formatProfileFields = (email, entitlements, accountType) => ({
    // eslint-disable-next-line no-nested-ternary
    accountType: typeof (accountType) === 'string' && accountType?.length > 0 ? (accountType === 'LDS' ? 'Schedule Access' : accountType) : NOT_AVAILABLE,
    name: NOT_AVAILABLE, // TEMPORARY: Putting N/A for fields that we don't keep track of yet
    email,
    organization: NOT_AVAILABLE, // TEMPORARY
    services: formatServices(entitlements)
});

export const GNVIEW_PLAN_FIELDS = [
    { prop: 'imageDomain', label: 'Image Domain' },
    { prop: 'roles', label: 'Roles' },
    { prop: 'streamId', label: 'Stream ID' },
    { prop: 'programLanguages', label: 'Program Languages' },
    { prop: 'countries', label: 'Schedule Countries' },
    { prop: 'catalogs', label: 'Streaming Video Catalogs' },
    { prop: 'additionalFeatures', label: 'Additional Features' }
];

export const formatGNViewPlanFields = (entitlements) => ({
    imageDomain: !entitlementIsEmpty(entitlements?.global?.include?.imageDomain) ? entitlements.global.include.imageDomain : NOT_AVAILABLE,
    roles: !entitlementIsEmpty(entitlements?.global?.include?.roles) ? entitlements.global.include.roles : 'Standard User',
    streamId: !entitlementIsEmpty(entitlements?.global?.include?.streamId) ? entitlements.global.include.streamId : NOT_AVAILABLE,
    programLanguages: !entitlementIsEmpty(entitlements?.programs?.include?.languages) ? convertLanguageCodeList(entitlements.programs.include.languages) : NOT_AVAILABLE,
    countries: !entitlementIsEmpty(entitlements?.schedules?.include?.countries) ? convertCountryCodes(entitlements.schedules.include.countries).map((countryObj) => countryObj.name) : NOT_AVAILABLE,
    catalogs: !entitlementIsEmpty(entitlements?.streamingVideos?.include?.catalogs) ? entitlements.streamingVideos.include.catalogs : NOT_AVAILABLE,
    additionalFeatures: formatAdditionalFeatures(entitlements?.programs?.include)
});

export const GNID_PLAN_FIELDS = [
    { prop: 'sourceId', label: 'Source ID' },
    { prop: 'access', label: 'Access' },
    { prop: 'languages', label: 'Languages' }
];

export const formatGNIDPlanFields = (entitlements) => ({
    sourceId: !entitlementIsEmpty(entitlements?.mobius?.include?.sourceId) ? entitlements.mobius.include.sourceId : NOT_AVAILABLE,
    access: !entitlementIsEmpty(entitlements?.mobius?.include?.access) ? entitlements.mobius.include.access : NOT_AVAILABLE,
    languages: !entitlementIsEmpty(entitlements?.mobius?.include?.language) ? convertLanguageCodeList(entitlements?.mobius?.include?.language) : NOT_AVAILABLE
});

export const SCHEDULE_GRID_FORMATS = {
    BROADCAST_DAY: {
        label: 'Broadcast Day (6:00am - 6:00am)',
        value: 'BROADCAST_DAY'
    },
    SCHEDULE_DAY: {
        label: 'Schedule Day (12:00am - 12:00am)',
        value: 'SCHEDULE_DAY'
    }
};

export const BROADCAST_DAY_START = 6;
export const SCHEDULE_DAY_START = 0;

export const USER_SETTINGS_LABELS = {
    COUNTRY: 'Country',
    GLOBAL_DEFAULT_SETTINGS: 'Global Default Settings',
    LANGUAGE: 'Language',
    SCHEDULE_GRID: 'Schedule Grid',
    SCHEDULE_GRID_START_TIME: 'Schedule Grid Start Time',
    TIMEZONE: 'Timezone'
};

export const GLOBAL_TIMEZONE_DEFAULT = {
    name: "Local Station Timezone",
    value: "Local Station Timezone"
};

export const GLOBAL_COUNTRY_DEFAULT = {
    name: 'All Countries',
    value: ''
};

export const GLOBAL_SETTINGS_DESCRIPTION = {
    MAIN_TEXT: 'Set your preferences here for your view of Gracenote View reporting.',
    TIMEZONE_TEXT_1: 'Select your preferred timezone to view airing reports and schedule grids.',
    TIMEZONE_TEXT_2: 'Local Station is the respective timezone of the reporting station.',
    LANGUAGE_TEXT: 'Select the default program language for use in the application',
    COUNTRY_TEXT: 'Select your default country for use in the application'
};

export const USER_SETTINGS_NOTIFICATIONS = {
    SAVE_SUCCESS: "Successfully saved!",
    SAVE_ERROR: "There was an error saving your settings."
};

export const USER_SETTINGS_CONFIRMATIONS = {
    CANCEL_CONFIRMATION_MESSAGE: "Make sure your changes are saved before exiting, otherwise they will be lost.",
    CANCEL_CONFIRMATION_TITLE: "Are you sure you want to exit?"
};

export const USER_SETTINGS_BUTTON_TEXT = {
    CANCEL: "Cancel",
    EXIT: "Exit",
    SAVE: "Save"
};

export const ACCOUNT_TYPES = {
    'Trial': 'Unlimited searches and access to all features during trial period',
    'Basic': 'Limited weekly searches and feature access',
    'Schedule Access': 'Access to specific channel schedules and corresponding programs',
    'Standard': 'Unlimited searches with lmited feature access',
    'Premium': 'Unlimited searches and access to all features'
}