import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { ROUTES } from '../../config/Routes';
import gnViewLogo from '../../images/GN_View_logo.png';
import './Landing.scss';
import {
    getCopyRightText,
    LANDING_CONTENT_BODY,
    LANDING_CONTENT_HEADER,
    LANDING_FOOTER_LINKS,
    LANDING_CONTACT_SALES,
    LEARN_WHAT_YOU_CAN_DO,
    WHAT_YOU_GET_DESC,
    WHAT_YOU_GET_HEADER,
    WHAT_YOU_GET_IMAGES,
    WHAT_YOU_GET_TYPES
} from '../../constants/Landing';
import { gvauthSelFail, gvauthSelFailErrorMessage } from '../../reducers/GNVAuthReducer';
import exploreCoverPhoto from '../../images/GN_View_Analyze.png';
import trackCoverPhoto from '../../images/GN_View_Track.png';
import accountCoverPhoto from '../../images/GN_View_Account.png';
import ClassNames from 'classnames';
import homepageCover from '../../images/homepage_hero.gif';
import { useSelector } from 'react-redux';
import { getPathAfterLogin } from '../../utils/GeneralUtils';
import { GNVIEW_AUTH } from '../../constants/LocalStorage';
import queryString from 'query-string';


export const Landing = () => {
    const history = useHistory();
    const [image, setImage] = useState(WHAT_YOU_GET_IMAGES[WHAT_YOU_GET_TYPES.PROGRAM_DETAILS].img);
    const serializedAuthState = localStorage.getItem(GNVIEW_AUTH);
    const authState = JSON.parse(serializedAuthState);
    const failedLogin = useSelector(gvauthSelFail);
    const failErrorMessage = useSelector(gvauthSelFailErrorMessage);

    useEffect(() => {
        const params = queryString.parse(window.location.search);
        // Save params to local storage
        localStorage.setItem('params', JSON.stringify(params));
        if (authState && authState?.entitlements) {
            const path = getPathAfterLogin(authState.entitlements);
            history.push(path);
        }
    }, [authState, history]);

    return (
        <div className="gnview-landing-content">
            {failedLogin && <div className='login-fail-message'>
                Error: Please contact your administrator
                <div className="error-message">Message: {failErrorMessage?.message}</div>
                <div className="stack-message">Stack: {failErrorMessage?.stack}</div>
            </div>}
            <div className='landing-header'>
                <img src={gnViewLogo} className='landing-gnlogo' alt='Gracenote View Logo' />
                <div className='buttons-container'>
                    <a href={LANDING_CONTACT_SALES.url} className='contact-sales-button'>
                        <Button variant='bright-blue' className='request-demo-button'>{LANDING_CONTACT_SALES.buttonText}</Button>
                    </a>
                    <Link to={ROUTES.TRIAL_FORM} className='free-trial-link'>
                        <Button className='free-trial-button'>{LANDING_CONTACT_SALES.freeTrial}</Button>
                    </Link>
                    <div className='left-border'></div>
                    <Link to={`${ROUTES.LOGIN}${window.location.search}`} className='login-link'>
                        <Button className='login-button'>Login</Button>
                    </Link>
                </div>
            </div>
            <div className='cover-photo-container'>
                <img src={homepageCover} alt='Gracenote Homepage Cover Gif' />
                <div className='landing-content-header'>
                    <div className='header-body-container'>
                        <div className='content-header-title'>{LANDING_CONTENT_HEADER.title}</div>
                        <div className='content-header-subtitle'>
                            {LANDING_CONTENT_HEADER.subtitle.map((sub, idx) => (
                                <div className='subtitle-item' key={idx}>
                                    <div><span className='subtitle-header'>{sub.header}</span></div>
                                    <div className='subtitle-subheader'>{sub.subheader}</div>
                                </div>
                            ))}
                        </div>
                        <a href={LANDING_CONTACT_SALES.url} className='contact-sales-button' id='request-btn'>
                            <Button variant='bright-blue' className={ClassNames('request-demo-button')}>{LANDING_CONTACT_SALES.buttonText}</Button>
                        </a>
                        <a href={ROUTES.TRIAL_FORM} className='free-trial-link'>
                            <Button className='free-trial-button'>{LANDING_CONTACT_SALES.freeTrial}</Button>
                        </a>
                    </div>
                </div>
            </div>
            <div className='landing-content-body'>
                <div className='landing-content-body-header'>{LEARN_WHAT_YOU_CAN_DO}</div>
                <div className='landing-content-body-tile'>
                    <div className='tile-text-container explore'>
                        <div className='tile-header'>{LANDING_CONTENT_BODY.explore.header}</div>
                        <div className='tile-subheader explore'>{LANDING_CONTENT_BODY.explore.subheader}</div>
                        <ul>
                            {LANDING_CONTENT_BODY.explore.bullets.map((point, idx) => (
                                <li className='tile-bullet-point' key={idx}>{point}</li>
                            ))}
                        </ul>
                    </div>
                    <div className='tile-image-container'>
                        <img src={exploreCoverPhoto} alt='Explore Photo' />
                        <div className='tile-triangle explore-one' />
                        <div className='tile-triangle explore-two' />
                        <div className='tile-triangle explore-three' />
                    </div>
                </div>
                <div className='landing-content-body-tile'>
                    <div className='tile-image-container'>
                        <img src={trackCoverPhoto} alt='Track Photo' />
                        <div className='tile-triangle track-one' />
                        <div className='tile-triangle track-two' />
                        <div className='tile-triangle track-three' />
                    </div>
                    <div className='tile-text-container track'>
                        <div className='tile-header'>{LANDING_CONTENT_BODY.track.header}</div>
                        <div className='tile-subheader track'>{LANDING_CONTENT_BODY.track.subheader}</div>
                        <ul>
                            {LANDING_CONTENT_BODY.track.bullets.map((point, idx) => (
                                <li className='tile-bullet-point' key={idx}>{point}</li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className='landing-content-body-tile'>
                    <div className='tile-text-container account'>
                        <div className='tile-header'>{LANDING_CONTENT_BODY.account.header}</div>
                        <div className='tile-subheader account'>{LANDING_CONTENT_BODY.account.subheader}</div>
                        <ul>
                            {LANDING_CONTENT_BODY.account.bullets.map((point, idx) => (
                                <li className='tile-bullet-point' key={idx}>{point}</li>
                            ))}
                        </ul>
                    </div>
                    <div className='tile-image-container'>
                        <img src={accountCoverPhoto} alt='Account Photo' />
                        <div className='tile-triangle account-one' />
                        <div className='tile-triangle account-two' />
                        <div className='tile-triangle account-three' />
                    </div>
                </div>
            </div>
            <div className='what-you-get-section-container'>
                <div className='landing-content-body-header'>{WHAT_YOU_GET_HEADER}</div>
                <div className='what-you-get-desc'>{WHAT_YOU_GET_DESC}</div>
                <img src={image} alt='What You Get Cover Photo' />
                <div className='what-you-get-selector'>
                    {Object.values(WHAT_YOU_GET_TYPES).map((type, idx) => {
                        const selected = image === WHAT_YOU_GET_IMAGES[type].img;
                        return (
                            <div className='selector-container' key={idx}>
                                <div
                                    className={ClassNames('selector-type', { [type]: true, selected })}
                                    onClick={() => setImage(WHAT_YOU_GET_IMAGES[type].img)}>
                                    {type}
                                </div>
                                {selected && <div className='selector-description'>{WHAT_YOU_GET_IMAGES[type].description}</div>}
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className='footer-content-container'>
                <div className='footer-text'>{getCopyRightText()}</div>
                <div>
                    {LANDING_FOOTER_LINKS.map((footerLink, index) => {
                        return <a href={footerLink.path} className='footer-link' key={index} target="_blank" rel="noreferrer">{footerLink.title}</a>
                    })}
                </div>
            </div>
        </div>
    )
}

export default Landing;