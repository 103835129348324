import React, { useEffect } from 'react';
import LoadingSpinner from '../loadingSpinner/LoadingSpinner';
import "./Login.scss"
import { ROUTES } from '../../config/Routes';
import { gnviewGetEntitlements, gnviewGetUserSettings } from '../../actions/GNViewAuthActions';
import queryString from 'query-string';
import Cookies from 'js-cookie';
import { API_CURRENT_VERSION } from '../../config/Api';
import {
    OLD_GNVIEW_AUTH,
    GNVIEW_AUTH,
    GNVIEW_EMAIL,
    GNVIEW_VERSION
} from '../../constants/LocalStorage';
import EnvironmentService from "../../services/EnvironmentService.js";
import { incLoginCounter } from "../../utils/Monitoring";
import { gnviewSendLogMessage } from '../../services/GeneralService';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getPathAfterLogin } from '../../utils/GeneralUtils';

export const Login = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const hash = queryString.parse(window.location.hash);
    const idToken = hash?.id_token;
    const accessToken = hash?.access_token;

    useEffect(() => {
        const oldState = localStorage.getItem(OLD_GNVIEW_AUTH);
        // For backwards compatibility - if a user is currently logged in with the old state, log them out.
        if (oldState) {
            const params = localStorage.getItem('params');
            localStorage.clear();
            localStorage.setItem('params', JSON.stringify(params));
            window.location.replace(ROUTES.LANDING);
        } else if (!idToken && !accessToken) {
            const isInDevMode = new EnvironmentService().getIsInDevMode();
            const { redirectPath } = queryString.parse(window.location.search);
            const stateValue = redirectPath && redirectPath?.length > 0 ? `&state=${redirectPath}` : '';
            // Route user to cognito login
            if (isInDevMode) {
                window.location.replace(`${COGNITO_DEV}${stateValue}`);
            } else {
                window.location.replace(`${ROUTES.COGNITO}${stateValue}`);
            }
        } else {
            // Get entitlements
            dispatch(gnviewGetEntitlements(idToken, accessToken))
                .then((userInfo) => {
                    // Clear local storage for every micro front-end. This will ensure no stale JWTs are stored elsewhere.
                    const params = localStorage.getItem('params');
                    localStorage.clear();
                    localStorage.setItem('params', JSON.stringify(params));
                    // Set cookies in browser, expiring after 1 day and will override any previous value
                    Cookies.set(GNVIEW_EMAIL, userInfo?.email, { expires: 1 });
                    Cookies.set(GNVIEW_VERSION, API_CURRENT_VERSION, { expires: 1 });

                    // Store auth state
                    localStorage.setItem(GNVIEW_AUTH, JSON.stringify(userInfo));

                    // Grafana/Prometheus login metrics
                    incLoginCounter({
                        email: userInfo?.email,
                        firstName: userInfo?.firstName,
                        groupEndDate: userInfo?.group?.endDate,
                        groupId: userInfo?.group?.id,
                        groupName: userInfo?.group?.name,
                        groupStartDate: userInfo?.group?.createdDate,
                        isTrial: userInfo?.group?.isTrial,
                        lastName: userInfo?.lastName
                    });

                    // Grabbing User Settings: User settings will automatically be stored within the state if successful, so only a catch is needed here for error
                    dispatch(gnviewGetUserSettings(userInfo?.email)).then(() => {
                        let path;
                        const state = hash?.state;
                        if (state && state?.length > 0) {
                            path = state;
                        } else {
                            path = getPathAfterLogin(userInfo?.entitlements);
                        }
                        // Successfully logged in, reroute to proper page
                        history.push(path);
                    }).catch((error) => {
                        dispatch(gnviewSendLogMessage(`gnviewGetUserSettings error: ${error.message}`, error));
                    });
                }).catch((error) => {
                    dispatch(gnviewSendLogMessage(`gnviewGetEntitlements error: ${error.message}`, error));
                    history.push(ROUTES.LANDING);
                });
        }
    }, [accessToken, dispatch, history, idToken, hash?.state]);

    return (
        <div className="gnview-login-content">
            <LoadingSpinner />
        </div>
    )
}

export default Login;